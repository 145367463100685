// gatsby-browser.ts
import { GatsbyBrowser } from 'gatsby';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  getSavedScrollPosition,
  prevRouterProps,
}) => {
  const disableScroll = location.state?.disableScroll;

  if (disableScroll) {
    return false;
  }

  const urlSearchParams = new URLSearchParams(location.search);
  const hasCatQueryParam = urlSearchParams.has('cat');

  if (hasCatQueryParam) {
    return false;
  }

  const savedPosition: [number, number] | null = getSavedScrollPosition(location);
  if (savedPosition) {
    return savedPosition;
  }

  return true;
};
